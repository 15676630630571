
import Vue from "vue";
import { Component } from "vue-property-decorator";
import OrderStore from "../../entity/OrderStore";
import Order from "../../entity/Order";
import OrderStatus, { OrderStatuses } from "../../entity/OrderStatus";
import OrderTable from "../Tables/OrderTable.vue";
import ModalOrdine from "../Modal/ModalOrdine.vue";
import { Disposable } from "@/TypedEvent";
import Session from "@/Session";

@Component({
  components: {
    OrderTable,
    ModalOrdine,
  },
})
export default class OrdiniEvasi extends Vue {
  private orders = new Array<OrderStore>();
  private paginationOptions = { perPage: 25, perPageOptions: [25, 50, 100] };
  private onNewOrder: Disposable;

  private async rowClick(orderID: string) {
    (<ModalOrdine>this.$refs.modalOrdine).open(await Order.getOne(`orders/${orderID}`));
  }

  private orderStatusChanged(order: Order) {
    const orderInArray = this.orders.find((item) => {
      return item.orderID == order.ID;
    });
    if (orderInArray !== undefined) orderInArray.status = order.lastStatus.status;
  }

  async mounted() {
    this.orders = await OrderStore.getOrders({ status: [OrderStatuses.New] });

    this.onNewOrder = Session.instance.onNewOrder.on(async (order) => {
      this.orders.push(await OrderStore.getOrder(order.ID));

      (<OrderTable>this.$refs.orderTable).forceSort();
    });
  }

  beforeDestroy() {
    this.onNewOrder.dispose();
  }
}
