declare global {
    interface ObjectConstructor {
        isEmpty(obj: Object): boolean;
        /**
        * Copy the values in common of the enumerable own properties from one or more source objects to a target object. If you want you can disable the typecheck.
        * @param typeCheck default is true
        */
        assignProperties(target: any, source: any, typeCheck?: boolean): void;
    }

    interface String {
        toDate(): Date;
        nullIfEmpty(): string | null;
    }

    interface Array<T> {
        delete(index: number): void;
        delete(item: T): void;
        delete(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): void;
    }

    /**
     * @param hour default is true
     */
    interface Date {
        prittify(hour: boolean): string;
    }
}

Object.isEmpty = function (obj: any): boolean {
    if (obj instanceof Date) return false;
    else if (obj === undefined || obj === null || obj === '') return true;
    else if (typeof obj === 'object' || Array.isArray(obj)) return Object.entries(obj).length === 0;
    else return false;
}

Object.assignProperties = function (target: any, source: any, typeCheck: boolean = true): void {
    for (const key in source) {
        if (key == 'constructor')
            continue;

        if (source[key]?.constructor !== undefined && target[key]?.constructor !== undefined)
            if (typeCheck && target[key].constructor === source[key].constructor)
                target[key] = source[key];
            else if (!typeCheck)
                target[key] = source[key];
    }
}

String.prototype.toDate = function (): Date {
    const date = Date.parse(this);

    if (!isNaN(date)) {
        return new Date(date);
    }
    else
        throw Error("Invalid date");
}

String.prototype.nullIfEmpty = function (): string | null {
    if (this === '')
        return null;
    else
        return this;
}

Array.prototype.delete = function <T>(predicate: ((value: T, index: number, obj: T[]) => unknown) | T | number, thisArg?: any): void {
    let index = -1;

    if (typeof predicate == "function")
        index = this.indexOf(this.find(predicate, thisArg));
    else if (predicate instanceof Object)
        index = this.indexOf(predicate);
    else if (typeof predicate == "number")
        index = predicate;

    if (index > -1)
        this.splice(index, 1);
}

Date.prototype.prittify = function (hour: boolean = true): string {
    let DD: string;
    let MM: string;
    let YYYY = this.getFullYear();
    let mm: string;
    let HH: string;

    if (this.getDate() < 10)
        DD = '0' + this.getDate();
    else
        DD = this.getDate();

    if (this.getMonth() + 1 < 10)
        MM = '0' + parseInt((this.getMonth() + 1).toString().replace("0", ""));
    else
        MM = this.getMonth() + 1;

    if (this.getMinutes() < 10)
        mm = '0' + this.getMinutes();
    else
        mm = this.getMinutes();

    if (this.getHours() < 10)
        HH = '0' + this.getHours();
    else
        HH = this.getHours();

    return `${DD}/${MM}/${YYYY} ${hour == true ? `${HH}:${mm}` : ''}`
}

export { };
