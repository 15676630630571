
import Vue from "vue";
import { Component } from "vue-property-decorator";
import OrderStore from "../../entity/OrderStore";
import Order from "../../entity/Order";
import OrderStatus, { OrderStatuses } from "../../entity/OrderStatus";
import OrderTable from "../Tables/OrderTable.vue";
import ModalOrdine from "../Modal/ModalOrdine.vue";
import { RuntimeEventSubscriber } from "../../util/Helper";
import { Disposable } from "@/TypedEvent";
import Session from "@/Session";

@Component({
  components: {
    OrderTable,
    ModalOrdine,
  },
})
export default class OrdiniEvasi extends Vue {
  private orders = new Array<OrderStore>();
  private localSearchFiler = { status: [OrderStatuses.Delivered, OrderStatuses.Closed] };
  private paginationOptions = { perPage: 25, perPageOptions: [25, 50, 100] };
  private onOrderStatusChanged: Disposable;

  async mounted() {
    this.orders = await OrderStore.getOrders({ status: [OrderStatuses.Delivered, OrderStatuses.Closed] });

    this.onOrderStatusChanged = Session.instance.onOrderStatusChanged.on(async (publicID) => {
      const lastStatus = await OrderStatus.getLast(publicID);

      if (lastStatus.status == OrderStatuses.Delivered || lastStatus.status == OrderStatuses.Closed) {
        const order = this.orders.find((orderStore) => {
          return orderStore.orderID == publicID;
        });

        if (order != null) {
          order.status = lastStatus.status;
          order.statusDescription = lastStatus.description;
          order.statusDate = lastStatus.createdAt;
        } else this.orders.push(await OrderStore.getOrder(publicID));
      } else
        this.orders.delete((order) => {
          return order.orderID == publicID;
        });
    });
  }

  private async rowClick(orderID: string) {
    (<ModalOrdine>this.$refs.modalOrdine).open(await Order.getOne(`orders/${orderID}`));
  }

  private orderStatusChanged(order: Order) {
    const orderInArray = this.orders.find((item) => {
      return item.orderID == order.ID;
    });
    if (orderInArray !== undefined) orderInArray.status = order.lastStatus.status;
  }

  beforeDestroy() {
    this.onOrderStatusChanged.dispose();
  }
}
