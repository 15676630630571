import _ from 'lodash';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';
import PPagination from 'src/components/UIComponents/Pagination.vue';
import AddProductOrdine from '../Modal/ModalAddProductOrdine.vue';
import Order, { NewOrder } from '../../entity/Order';
import OrderStatus, { OrderStatuses } from '../../entity/OrderStatus';
import Product from '../../entity/Product';
import Helper from '../../util/Helper';
import ProductSize from '../../entity/ProductSize';
import Swal from 'sweetalert2';
import { plainToInstance } from 'class-transformer';
import Item from '../../entity/Item';
import ModalStatusHistory from '@/views/Modal/ModalStatusHistory.vue';
import Session from '@/Session';
import UserCustomerStore from '@/entity/UserCustomer/UserCustomerStore';

@Component({
    name: 'ModalOrdine',
    components: { SlideYUpTransition, PPagination, AddProductOrdine, ModalStatusHistory },
})
export default class ModalOrdine extends Vue {
    private show = false;
    private order = new Order();
    private modalStatusHistory: ModalStatusHistory;
    private pdfLoading = false;
    private isNewOrder = false;
    private rawUserCustomers = new Array<UserCustomerStore>();
    private userCustomers = new Array();
    private selectedUserCustomerID = '';

    private componentKey = {
        table: 0,
        productSelect: 1,
        quantityInput: 2,
        saveBtn: 3,
        abortBtn: 4,
        delBtn: 5,
    };
    private newOrderCard = {
        loading: false,
    };
    private newOrder = new NewOrder();
    private rules = {
        product: {
            required: true,
        },
        productSize: {
            required: true,
        },
    };
    private newProduct = {
        visible: false,
    };
    private pagination = {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
    };
    private tableColumnSize = {
        quantity: 105,
        package: 105,
        buttons: 180,
    };
    private searchQuery = '';

    private get pagedData(): Item[] {
        if (this.newOrder.newItems) {
            return this.newOrder.newItems.slice(this.from, this.to);
        }
        else {
            return [];
        }
    }

    private get queriedData(): Item[] {
        if (!this.searchQuery) {
            if (this.newOrder.newItems) {
                this.pagination.total = this.newOrder.newItems.length;
            }
            else {
                this.pagination.total = 0;
            }

            return this.pagedData;
        }

        const result = this.newOrder.newItems.filter((row) => {
            let isIncluded = false;

            for (const key of ['commercialName', 'producer', 'description']) {
                const rowValue = row.product[key].toString().toLowerCase();

                if (rowValue.includes && rowValue.includes(this.searchQuery.toString().toLowerCase())) {
                    isIncluded = true;
                }
            }

            return isIncluded;
        });

        this.pagination.total = result.length;

        return result.slice(this.from, this.to);
    }

    private get to(): number {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    }

    private get from(): number {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    }

    private get total(): number {
        if (this.newOrder.newItems) {
            this.pagination.total = this.newOrder.newItems.length;
        }
        else {
            this.pagination.total = 0;
        }

        return this.pagination.total;
    }

    private get prittifyDueDate(): string {
        if (this.newOrder?.dueDate != null) {
            return Helper.prittifyDate(this.newOrder.dueDate);
        }
    }

    private get getMail(): string {
        return this.newOrder?.userCustomer?.email ?? '';
    }

    private get address(): string {
        return this.newOrder?.address ?? '';
    }

    private get notes(): string {
        return this.newOrder?.notes ?? '';
    }

    private get getPhone(): string {
        return this.newOrder?.userCustomer?.cellPhone ?? '';
    }

    private get getFullName(): string {
        return this.newOrder?.userCustomer?.fullname ?? '';
    }

    private get getStatusString(): string {
        if (this.newOrder?.lastStatus != null) {
            return OrderStatuses.toString(this.newOrder.lastStatus.status);
        }
    }

    private get isNew(): boolean {
        if (this.newOrder?.lastStatus != null) {
            return this.newOrder.lastStatus.status === OrderStatuses.New;
        }

        return false;
    }

    private get createdAt(): string {
        if (this.newOrder?.createdAt != null) {
            return Helper.prittifyDate(this.newOrder.createdAt);
        }

        return '';
    }

    private get orderCreator(): string {
        return this.newOrder?.userBusinessOrder?.userBusiness.fullname ?? '';
    }

    private get isFromUserCustomer(): boolean {
        return this.newOrder?.isFromUserCustomer;
    }

    private get isOrderChanged(): boolean {
        if (this.isNewOrder) {
            if (this.newOrder.userCustomer?.ID != null && this.newOrder.newItems.length > 0) {
                return true;
            }

            return false;
        }

        return this.newOrder.newItems.length !== this.order?.items?.length || !_.isMatch(this.newOrder.newItems, this.order.items);
    }

    private get isOrderRunning(): boolean {
        if (this.newOrder?.lastStatus?.status != null) {
            return OrderStatuses.isRunning(this.newOrder.lastStatus.status);
        } else {
            return false;
        }
    }

    private get hasValidUserCustomer(): boolean {
        return this.newOrder?.userCustomer?.ID != null;
    }

    private get buttonType() {
        if (this.newOrder?.lastStatus?.status == OrderStatuses.New) {
            return 'primary';
        } else if (this.newOrder?.lastStatus?.status == OrderStatuses.Pending) {
            return 'warning';
        } else if (
            this.newOrder?.lastStatus?.status == OrderStatuses.Approved ||
            this.newOrder?.lastStatus?.status == OrderStatuses.Delivering ||
            this.newOrder?.lastStatus?.status == OrderStatuses.Delivered
        ) {
            return 'success';
        } else if (this.newOrder?.lastStatus?.status == OrderStatuses.Rejected) {
            return 'danger';
        } else {
            return 'info';
        }
    }

    private editingCounter = 0;
    private get isEditing(): boolean {
        return this.editingCounter > 0;
    }

    private async remoteMethod(query, row): Promise<void> {
        if (query !== '') {
            row.editProduct.loading = true;
            this.renderProductSelect();

            row.editProduct.products = await Product.getMany(`products?search=${query}`);

            row.editProduct.options = row.editProduct.products.map((item) => {
                return { value: item.ID, label: item.commercialName };
            });

            row.editProduct.loading = false;
            this.renderProductSelect();
        } else {
            row.editProduct.products = [];
            row.editProduct.options = [];
        }
    }

    private async getUserCustomers(query: string): Promise<void> {
        this.rawUserCustomers = [];
        this.rawUserCustomers.push(...(await Session.instance.userBusiness.store.getUserCustomers(query)));

        this.userCustomers = this.rawUserCustomers.map((userCustomerStore) => {
            return { value: userCustomerStore.userCustomer.ID, label: userCustomerStore.userCustomer.fullname };
        });
    }

    private userCustomerSelected(userCustomerID: string): void {
        const userCustomer = this.rawUserCustomers.find((userCustomerStore) => {
            return userCustomerStore.userCustomer.ID == userCustomerID;
        })?.userCustomer;

        if (userCustomer != null) {
            this.newOrder.userCustomer = userCustomer;
        }
    }

    private productSelected(selected, row): void {
        row.editProductSize.selected = '';
        this.renderTable();

        const selectedProduct = row.editProduct.products.find((item) => {
            return item.ID == selected;
        });

        row.editProduct.producer = selectedProduct.producername;

        row.editProductSize.options = selectedProduct.productSizes.map((item) => {
            return { value: item.ID, label: item.getPackage };
        });

        row.editProductSize.enabled = true;

        row.editResult.product = selectedProduct;
    }

    private productSizeSelected(selected, row): void {
        row.editResult.size = row.editResult.product.productSizes.find((item) => {
            return item.ID == selected;
        });

        this.renderTable();
    }

    private closeModal(): void {
        this.show = false;
        this.$emit('close');
    }

    private sendEmail(): void {
        window.location.href = 'mailto:' + this.getMail + '?subject=Easyfito';
    }

    private call(): void {
        window.location.href = 'tel:' + this.getPhone;
    }

    private async acceptOrder(): Promise<void> {
        await this.newStatus(OrderStatuses.Approved);
    }

    private async refuseOrder(): Promise<void> {
        await this.newStatus(OrderStatuses.Rejected);
    }

    private async createPDF(): Promise<void> {
        this.pdfLoading = true;

        try {
            const pdf = await Order.getPDF(this.order.ID);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(pdf.blob);
            link.download = pdf.filename;
            link.click();
            URL.revokeObjectURL(link.href);
            link.remove();
        } catch (error) {
            this.$message.error({ message: 'Errore nel download del PDF', showClose: true });
        }

        this.pdfLoading = false;
    }

    private addProductResult(result): void {
        const productInArray = this.newOrder?.newItems?.find((item) => {
            return item.product.ID === result.product.ID && item.size.ID === result.size.ID;
        });

        if (productInArray != null) {
            productInArray.quantity += result.quantity;
        } else {
            this.newOrder.newItems.push(plainToInstance(Item, result));
        }
    }

    private async editMode(row, value): Promise<void> {
        if (value) {
            this.editingCounter++;

            row.loading = true;
            this.renderTable();

            await this.remoteMethod(row.product.commercialName, row);
            row.editProduct.selected = row.editProduct.options[0].label;
            this.productSelected(row.editProduct.options[0].value, row);

            const productSize = row.editProductSize.options.find((item) => {
                return item.value === row.size.ID;
            });

            row.editProductSize.selected = productSize.label;
            this.productSizeSelected(productSize.value, row);

            row.editResult.quantity = row.quantity;

            if (this.isEditing) {
                this.tableColumnSize.quantity = 150;
                this.tableColumnSize.package = 130;
                this.tableColumnSize.buttons = 225;
            }

            row.loading = false;
        } else {
            this.editingCounter--;

            if (!this.isEditing) {
                this.tableColumnSize.quantity = 105;
                this.tableColumnSize.package = 105;
                this.tableColumnSize.buttons = 180;
            }
        }

        row.edit = value;
        this.renderTable();
    }

    private async save(row): Promise<void> {
        const isValid = await this.$validator.validateAll(row.ID);

        if (isValid) {
            row.saving = true;
            this.renderButtons();

            this.newOrder.newItems.forEach((item) => {
                if (item.ID === row.ID) {
                    if (item.size.ID !== row.editResult.size.ID) {
                        const productInArray = this.newOrder.newItems.find((item) => {
                            return item.product.ID === row.editResult.product.ID && item.size.ID === row.editResult.size.ID;
                        });

                        if (productInArray != null) {
                            productInArray.quantity += row.editResult.quantity;
                            this.newOrder.newItems.delete(row);
                        } else {
                            Object.assign(item.product, Product, row.editResult.product);
                            Object.assign(item.size, ProductSize, row.editResult.size);
                            item.quantity = row.editResult.quantity;
                        }
                    } else if (item.quantity !== row.editResult.quantity) {
                        item.quantity = row.editResult.quantity;
                    }
                }
            });

            row.saving = false;
            this.editMode(row, false);
        }
    }

    private remove(row): void {
        if (this.newOrder.newItems.length > 1) {
            this.newOrder.newItems.delete(row);
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Impossibile cancellare tutti i prodotti.',
            });
        }
    }

    private openStatusHistory(): void {
        (<any>this.modalStatusHistory).open(this.order.ID);
    }

    private statusHistoryChanged(orderStatus: OrderStatus): void {
        this.newOrder.lastStatus = orderStatus;
    }

    private async newStatus(orderStatus: OrderStatuses): Promise<void> {
        const newStatus = new OrderStatus();
        newStatus.status = orderStatus;

        await newStatus.postOne(`orders/${this.order.ID}/statushistory`);

        this.newOrder.lastStatus = newStatus;

        this.$emit('orderStatusChanged', this.newOrder);

        this.$message({
            showClose: true,
            message: `Stato dell'ordine modificato`,
            type: 'success',
        });
    }

    private confirmClose(): void {
        if (!this.isOrderChanged) {
            this.closeModal();
            return;
        }

        this.$confirm('Ci sono modifiche non salvate, chiudere ugualmente?', 'Modifiche non salvate', {
            confirmButtonText: 'SÌ',
            cancelButtonText: 'Annulla',
            type: 'warning',
        }).then(() => {
            this.closeModal();
        }).catch(() => {
            return;
        });
    }

    private async saveChanges(): Promise<void> {
        if (!this.isOrderChanged) {
            return;
        }

        const loading = this.$loading({
            text: 'Modifica in corso...',
            target: '#modalContent',
        });

        try {
            if (this.isNewOrder) {
                await this.newOrder.createOrder();
            } else {
                await this.newOrder.updateItems();
            }
            this.$message({
                showClose: true,
                message: 'Ordine modificato correttamente',
                type: 'success',
            });

            this.$emit('orderChanged', this.newOrder);
            this.closeModal();
        } catch {
            this.$message({
                showClose: true,
                message: 'Qualcosa è andato storto, riprova',
                type: 'error',
            });
        } finally {
            loading.close();
        }
    }

    private renderTable(): void {
        this.componentKey.table++;
    }

    private renderProductSelect(): void {
        this.componentKey.productSelect++;
    }

    private renderQantityInput(): void {
        this.componentKey.quantityInput++;
    }

    private renderButtons(): void {
        this.componentKey.saveBtn++;
        this.componentKey.abortBtn++;
        this.componentKey.delBtn++;
    }

    private removeNowBtn(): void {
        setTimeout(() => {
            const elements = document.getElementsByClassName('el-button el-picker-panel__link-btn el-button--text el-button--mini'); //[0].remove();
            elements[0]?.remove();
        }, 1);
    }

    public open(order?: Order): void {
        Object.assign(this, this.initData());

        if (order == null) {
            this.isNewOrder = true;
            this.order = new Order();
            this.newOrder = new NewOrder();
            this.show = true;
        } else {
            this.isNewOrder = false;
            this.order = order;
            this.newOrder = new NewOrder(this.order);
            this.show = true;
        }
    }

    private initData() {
        return {
            editingCounter: 0,
            componentKey: {
                table: 0,
                productSelect: 1,
                quantityInput: 2,
                saveBtn: 3,
                abortBtn: 4,
                delBtn: 5,
            },
            newOrderCard: {
                loading: false,
            },
            isNewOrder: false,
            rawUserCustomers: new Array<UserCustomerStore>(),
            userCustomers: new Array(),
            selectedUserCustomerID: '',
            rules: {
                product: {
                    required: true,
                },
                productSize: {
                    required: true,
                },
            },
            newProduct: {
                visible: false,
            },
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [10, 25, 50],
                total: 0,
            },
            tableColumnSize: {
                quantity: 105,
                package: 105,
                buttons: 180,
            },
            searchQuery: '',
        };
    }

    mounted() {
        this.modalStatusHistory = <any>this.$refs.modalStatusHistory;
    }

    @Watch('show')
    private showChanged(val: boolean) {
        let documentClasses = document.body.classList;
        if (val) {
            documentClasses.add('modal-open');
        } else {
            documentClasses.remove('modal-open');
        }
    }

    @Watch('newOrder.items')
    private orderItemsChanged(val) {
        for (let item of val) {
            if (!val.hasOwnProperty('edit')) {
                item.edit = false;
                item.loading = false;
                item.saving = false;
                item.editProduct = {
                    selected: '',
                    producer: '',
                    products: [],
                    options: [],
                    loading: false,
                };
                item.editProductSize = {
                    selected: '',
                    options: [],
                    enabled: false,
                };
                item.editResult = {
                    product: null,
                    size: null,
                    quantity: 1,
                };
            }
        }
    }
}
