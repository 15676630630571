import { APIError } from '@/entity/RequestManager';
import UserBusiness from '@/entity/UserBusiness/UserBusiness';
import { ElForm } from 'element-ui/types/form';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';

@Component({ name: 'EditUserBusiness', components: { SlideYUpTransition } })
export default class EditUserBusiness extends Vue {
    private show = false;
    private isLoading = false;
    private hadOpenClass = false;
    private userFormRef: ElForm;
    private user: UserBusiness;

    private userForm = {
        firstname: '',
        lastname: '',
        email: ''
    }

    private userFormRules = {
        firstname: [{ required: true, message: 'Inserire il nome', trigger: 'change' }, { min: 3, message: 'Nome troppo corto', trigger: 'change' }],
        lastname: [{ required: true, message: 'Inserire il cognome', trigger: 'change' }, { min: 3, message: 'Cognome troppo corto', trigger: 'change' }],
        email: [{ required: true, validator: this.validateEmail, trigger: 'change' }]
    }

    public open(user: UserBusiness) {
        this.userFormRef.resetFields();

        this.user = null;
        this.user = user;

        this.userForm.firstname = user.firstName;
        this.userForm.lastname = user.lastName;
        this.userForm.email = user.mail;

        this.show = true;
    }

    private async updateUser() {
        try {
            if (this.userForm.firstname == this.user.firstName && this.userForm.lastname == this.user.lastName) {
                this.closeModal();

                return;
            }

            this.isLoading = true;
            await this.userFormRef.validate()

            await UserBusiness.update(this.user.ID, { firstname: this.userForm.firstname, lastname: this.userForm.lastname });

            this.closeModal();
            this.$emit('userUpdated', { ID: this.user.ID, firstname: this.userForm.firstname, lastname: this.userForm.lastname });
            this.$message.success({ message: 'Utente modificato con successo', showClose: true });
        }
        catch (error) {
            if (error instanceof APIError) {
                let message = '';

                if (error.code == 10112)
                    message = 'Email già esistente';
                else if (error.code == 10111)
                    message = 'Nome o cognome troppo lunghi';
                else if (error.code == 10109)
                    message = 'Nome o cognome troppo corti';
                else
                    message = `Errore codice: ${error.code}. Contattare l'assistenza`;

                this.$message.error({ message: message, showClose: true });
                return;
            }

            this.$message.error({ message: 'Errore sconosciuto. Contattare l\'assistenza', showClose: true });
        }
        finally {
            this.isLoading = false;
        }
    }

    private confirmClose() {
        this.closeModal()
    }

    private closeModal() {
        this.show = false;
        this.$emit('close');
    }

    mounted() {
        this.userFormRef = <ElForm>this.$refs.userForm;
    }

    @Watch('show')
    private showChanged(val) {
        const documentClasses = document.body.classList;

        if (val && !this.hadOpenClass)
            documentClasses.add('modal-open');
        else if (!val && !this.hadOpenClass)
            documentClasses.remove('modal-open');
    }

    private validateEmail(rule, value, callback) {
        if (value === '')
            callback(new Error('Inserire un\'email'));
        else if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm.test(value)) {
            callback(new Error('Inserire un\'email valida'));
        }
        else
            callback();
    }
}
