<template>
  <el-popover
    ref="popover"
    placement="right"
    width="200"
    trigger="manual"
    :title="title"
    :content="status"
    :value="visible"
  ></el-popover>
</template>
<script>
export default {
  name: "CalendarPopover",
  props: {
    title: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 200
    },
    parentElement: {
      type: Element
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {},
  mounted() {
    this.parentElement.addEventListener("mouseover", () => {
      this.visible = true;
    });

    this.parentElement.addEventListener("mouseleave", () => {
      this.visible = false;
    });
  }
};
</script>